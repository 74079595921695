<template>
  <div>
    <main>
      <slot />
    </main>
    <TheTeCommonFooterLp />
  </div>
</template>

<script lang="ts">
import TheTeCommonFooterLp from '~/components/Te/TeCommon/Footer/TheTeCommonFooterLp.vue'

const options = {
  components: {
    TheTeCommonFooterLp,
  },
}

export default defineNuxtComponent(options)
</script>
