
<template>
  <footer class="te-footer bg-co-gray-700">
    <div class="co-container-lg px-4 py-5">
      <div class="text-center">
        <a
          class="co-text-white-2 co-p-0 mx-auto text-co-gray-100 underline decoration-1"
          href="/company"
        >
          運営者情報
        </a>
      </div>
      <div class="text-center text-co-gray-100">
        <small>
          Copyright (C) {{ currentYear }} All Rights Reserved.
        </small>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useDateFormat, useNow } from '@vueuse/core'

const currentYear = useDateFormat(useNow(), 'YYYY')
</script>
